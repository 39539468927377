import "@browniebroke/gatsby-image-gallery/dist/style.css";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import React from "react";
import Footer from "../components/Footer";
import Header from "../components/header";
import SEO from "../components/seo";

function GladiorPhoto({ photoInfo }) {
  return (
    <figure className="relative  cursor-pointer group">
      <Img
        className="shadow-xl hover:shadow-2xl"
        imgStyle={{ objectFit: "contain" }}
        style={{ height: "100%" }}
        fluid={photoInfo.small}
      />
      <figcaption className="absolute text-lg -mt-16 text-white px-4 hidden group-hover:block">
        <div>
          <h2 className="font-semibold font-display text-base sm:text-xl">
            {photoInfo.title}
          </h2>
        </div>
        <div>
          <p className="text-sm sm:text-base">{photoInfo.subTitle}</p>
        </div>
      </figcaption>
    </figure>
  );
}

function WijZijnGladior({}) {
  const data = useStaticQuery(
    graphql`
      query {
        images: allFile(
          filter: { relativeDirectory: { eq: "gallery" } }
          sort: { fields: name }
        ) {
          edges {
            node {
              id
              thumb: childImageSharp {
                fluid(maxWidth: 270, maxHeight: 270) {
                  ...GatsbyImageSharpFluid
                }
              }
              full: childImageSharp {
                fluid(
                  maxWidth: 1024
                  quality: 85
                  srcSetBreakpoints: [576, 768, 992, 1200]
                ) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }

        gladiorPhoto: file(relativePath: { eq: "wij-zijn-gladior-2020.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 4160) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
            fixed(height: 400) {
              ...GatsbyImageSharpFixed_withWebp_tracedSVG
            }
          }
        }

        consultancyPhoto: file(
          relativePath: { eq: "business-consultancy-team.jpg" }
        ) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 4160) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }

        dataSolutionsPhoto: file(
          relativePath: { eq: "team-data-solutions.jpg" }
        ) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 4160) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }

        managementPhoto: file(relativePath: { eq: "team-management.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 4160) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }

        backofficePhoto: file(relativePath: { eq: "team-backoffice.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 4160) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }

        marketingPhoto: file(
          relativePath: { eq: "performance-marketing.jpg" }
        ) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 4160) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }

        salesPhoto: file(relativePath: { eq: "team-sales.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 4160) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }

        webPhoto: file(relativePath: { eq: "teamweb.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 4160) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }

        stefDeRon: file(relativePath: { eq: "stef-de-ron.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 4160) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }

        thomas: file(relativePath: { eq: "thomas.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 4160) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }

        vincent: file(relativePath: { eq: "vincent.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 4160) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }

        robin: file(relativePath: { eq: "robin.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 4160) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }

        damian: file(relativePath: { eq: "damian.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 4160) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }

        ellen: file(relativePath: { eq: "ellen.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 4160) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }

        karin: file(relativePath: { eq: "karin.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 4160) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }

        kai: file(relativePath: { eq: "kai.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 4160) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }

        tamara: file(relativePath: { eq: "tamara.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 4160) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }

        kevin: file(relativePath: { eq: "kevin.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 4160) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }

        stefRoelofs: file(relativePath: { eq: "stef-roelofs.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 4160) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }

        bandi: file(relativePath: { eq: "bandi.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 4160) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }

        rik: file(relativePath: { eq: "rik.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 4160) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }

        koen: file(relativePath: { eq: "koen.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 4160) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }

        daan: file(relativePath: { eq: "daan.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 4160) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }

        menno: file(relativePath: { eq: "menno.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 4160) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }

        stefan: file(relativePath: { eq: "stefan.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 4160) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }

        michel: file(relativePath: { eq: "michel.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 4160) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }

        dion: file(relativePath: { eq: "dion.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 4160) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }

        carolina: file(relativePath: { eq: "carolina.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 4160) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }

        wouter: file(relativePath: { eq: "wouter.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 4160) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }

        zoeDuFrenne: file(relativePath: { eq: "zoe-du-frenne.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 4160) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }

        kieran: file(relativePath: { eq: "kieran.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 4160) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }

        michelBeld: file(relativePath: { eq: "michel-beld.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 4160) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }

        maarten: file(relativePath: { eq: "maarten.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 4160) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }

        wessel: file(relativePath: { eq: "wessel.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 4160) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }

        zoe: file(relativePath: { eq: "zoe.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 4160) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }

        caroline: file(relativePath: { eq: "caroline.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 4160) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }

        lianne: file(relativePath: { eq: "lianne.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 4160) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }

        naomi: file(relativePath: { eq: "naomi.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 4160) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }

        marcel: file(relativePath: { eq: "marcel.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 4160) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }

        bandi2: file(relativePath: { eq: "bandi2.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 4160) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }

        peter: file(relativePath: { eq: "peter.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 4160) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `
  );

  let photos = {
    gladior: {
      title: "Gladior | #HereToStay",
      subTitle: "Data Driven Digital Marketing",
      small: data.gladiorPhoto.childImageSharp.fluid,
    },

    "team-marketing": {
      title: "Team Performance Marketing",
      subTitle: "Stef, Thomas, Vincent, Robin, Damian",
      small: data.marketingPhoto.childImageSharp.fluid,
    },

    "stef-de-ron": {
      title: "Stef",
      subTitle: "Performance Marketeer",
      small: data.stefDeRon.childImageSharp.fluid,
    },

    thomas: {
      title: "Thomas",
      subTitle: "Performance Marketeer",
      small: data.thomas.childImageSharp.fluid,
    },
    vincent: {
      title: "Vincent",
      subTitle: "Performance Marketeer",
      small: data.vincent.childImageSharp.fluid,
    },
    robin: {
      title: "Robin",
      subTitle: "Team Lead Performance Marketing",
      small: data.robin.childImageSharp.fluid,
    },

    damian: {
      title: "Damian",
      subTitle: "Performance Marketeer",
      small: data.damian.childImageSharp.fluid,
    },

    ellen: {
      title: "Ellen",
      subTitle: "Stagiare Performance Marketeer",
      small: data.ellen.childImageSharp.fluid,
    },

    karin: {
      title: "Karin",
      subTitle: "Stagiare Performance Marketeer",
      small: data.karin.childImageSharp.fluid,
    },

    "team-consultancy": {
      title: "Business Consultancy",
      subTitle: "Kai-Arne, Tamara, Kevin, Stef, Bandi",
      small: data.consultancyPhoto.childImageSharp.fluid,
    },

    kai: {
      title: "Kai-Arne",
      subTitle: "Manager Business Consultancy",
      small: data.kai.childImageSharp.fluid,
    },

    tamara: {
      title: "Tamara",
      subTitle: "Business Consultant",
      small: data.tamara.childImageSharp.fluid,
    },
    kevin: {
      title: "Kevin",
      subTitle: "Business Consultant",
      small: data.kevin.childImageSharp.fluid,
    },

    stefRoelofs: {
      title: "Stef",
      subTitle: "Business Consultant",
      small: data.stefRoelofs.childImageSharp.fluid,
    },

    bandi: {
      title: "Bandi",
      subTitle: "Manager Business Consultancy",
      small: data.bandi.childImageSharp.fluid,
    },

    rik: {
      title: "Rik",
      subTitle: "Business Consultant / Commercie",
      small: data.rik.childImageSharp.fluid,
    },

    "data-solutions": {
      title: "Data Solutions",
      subTitle: "Koen, Daan, Menno",
      small: data.dataSolutionsPhoto.childImageSharp.fluid,
    },

    koen: {
      title: "Koen",
      subTitle: "Software Developer",
      small: data.koen.childImageSharp.fluid,
    },

    daan: {
      title: "Daan",
      subTitle: "Software Developer",
      small: data.daan.childImageSharp.fluid,
    },

    menno: {
      title: "Menno",
      subTitle: "Software Developer",
      small: data.menno.childImageSharp.fluid,
    },

    stefan: {
      title: "Stefan",
      subTitle: "Software Developer",
      small: data.stefan.childImageSharp.fluid,
    },

    peter: {
      title: "Peter",
      subTitle: "Manager Data Solutions",
      small: data.peter.childImageSharp.fluid,
    },

    "team-web": {
      title: "Design & Development",
      subTitle:
        "Wessel, Wouter, Michel, Dion, Carolina, Zoé, Kieran, Michel, Maarten",
      small: data.webPhoto.childImageSharp.fluid,
    },

    michel: {
      title: "Michel",
      subTitle: "Manager Design & Development",
      small: data.michel.childImageSharp.fluid,
    },

    dion: {
      title: "Dion",
      subTitle: "Webdeveloper",
      small: data.dion.childImageSharp.fluid,
    },

    carolina: {
      title: "Carolina",
      subTitle: "Digital Designer",
      small: data.carolina.childImageSharp.fluid,
    },
    wouter: {
      title: "Wouter",
      subTitle: "Digital Designer",
      small: data.wouter.childImageSharp.fluid,
    },
    zoeDuFrenne: {
      title: "Zoé",
      subTitle: "Data Marketeer",
      small: data.zoeDuFrenne.childImageSharp.fluid,
    },

    kieran: {
      title: "Kieran",
      subTitle: "Webdeveloper",
      small: data.kieran.childImageSharp.fluid,
    },

    michelBeld: {
      title: "Michel",
      subTitle: "Webdeveloper",
      small: data.michelBeld.childImageSharp.fluid,
    },

    maarten: {
      title: "Maarten",
      subTitle: "Webdeveloper",
      small: data.maarten.childImageSharp.fluid,
    },

    wessel: {
      title: "Wessel",
      subTitle: "Webdeveloper",
      small: data.wessel.childImageSharp.fluid,
    },

    "team-backoffice": {
      title: "Backoffice",
      subTitle: "Zoë, Caroline",
      small: data.backofficePhoto.childImageSharp.fluid,
    },

    zoe: {
      title: "Zoë",
      subTitle: "Business Consultant / Commercie",
      small: data.zoe.childImageSharp.fluid,
    },

    caroline: {
      title: "Caroline",
      subTitle: "Business controller",
      small: data.caroline.childImageSharp.fluid,
    },

    lianne: {
      title: "Lianne",
      subTitle: "HR-assistent/administratief medewerker",
      small: data.lianne.childImageSharp.fluid,
    },

    "team-sales": {
      title: "Commercie",
      subTitle: "Bandi, Naomi, Marcel",
      small: data.salesPhoto.childImageSharp.fluid,
    },

    naomi: {
      title: "Naomi",
      subTitle: "Manager Commercie",
      small: data.naomi.childImageSharp.fluid,
    },

    marcel: {
      title: "Marcel",
      subTitle: "Eigenaar",
      small: data.marcel.childImageSharp.fluid,
    },

    bandi2: {
      title: "Bandi",
      subTitle: "Manager Business Consultancy",
      small: data.bandi2.childImageSharp.fluid,
    },

    "team-management": {
      title: "Management Team",
      subTitle: "Kai-Arne, Michel, Naomi, Marcel, Bandi",
      small: data.managementPhoto.childImageSharp.fluid,
    },
  };

  return (
    <React.Fragment>
      <SEO
        title={"Dit zijn de bandleden van Gladior"}
        description={
          "Wij werken als een team samen in de snelveranderende wereld van digital marketing. Bekijk hier de bandleden. Wij zijn Gladior!"
        }
        pathname={"/wij-zijn-gladior"}
      />

      <div className="flex flex-col font-sans min-h-screen text-gray-900">
        <Header noPhotoBackground={true} />

        <main className=" bg-gladior-dark px-0 md:px-6 py-10  flex flex-col justify-center  ">
          <div className="container mx-auto px-0 md:px-6 text-white ">
            <div className="w-full text-center ">
              <h1 className=" mt-2  text-4xl leading-tight xl:text-5xl font-semibold font-display text-white">
                Wij zijn Gladior
              </h1>

              <p className="text-3xl  font-semibold text-gladior-pink tracking-wider font-display">
                #HereToStay
              </p>
            </div>
            <div className="px-2  mt-10">
              <div className="flex flex-wrap -mx-2 ">
                <div className=" w-full px-2 mb-4 ">
                  <div className="bg-gray-400">
                    <GladiorPhoto photoInfo={photos["gladior"]} />
                  </div>
                </div>
              </div>

              <div className="flex flex-wrap -mx-2 flex-col-reverse sm:flex-row ">
                <div className=" w-full  sm:w-1/3  ">
                  <div className="px-2 mb-3">
                    <GladiorPhoto photoInfo={photos["koen"]} />
                  </div>
                  <div className="px-2 mb-3">
                    <GladiorPhoto photoInfo={photos["daan"]} />
                  </div>
                </div>

                <div className=" w-full  sm:w-2/3 px-2 mb-4 ">
                  <div className="bg-gray-400">
                    <GladiorPhoto photoInfo={photos["data-solutions"]} />
                  </div>
                </div>
              </div>

              <div className="flex flex-wrap -mx-2 ">
                <div className=" w-full  sm:w-1/3 px-2 mb-4 ">
                  <div className="bg-gray-400">
                    <GladiorPhoto photoInfo={photos["menno"]} />
                  </div>
                </div>
                <div className=" w-full  sm:w-1/3 px-2 mb-4 ">
                  <div className="bg-gray-400">
                    <GladiorPhoto photoInfo={photos["stefan"]} />
                  </div>
                </div>
                <div className=" w-full  sm:w-1/3 px-2 mb-4 ">
                  <div className="bg-gray-400">
                    <GladiorPhoto photoInfo={photos["peter"]} />
                  </div>
                </div>
              </div>

              <div className="flex flex-wrap -mx-2 flex-col-reverse sm:flex-row ">
                <div className=" w-full  sm:w-2/3 px-2 mb-4 ">
                  <div className="bg-gray-400">
                    <GladiorPhoto photoInfo={photos["team-web"]} />
                  </div>
                </div>
                <div className=" w-full  sm:w-1/3  ">
                  <div className="px-2 mb-3">
                    <GladiorPhoto photoInfo={photos["wessel"]} />
                  </div>
                  <div className="px-2 mb-3">
                    <GladiorPhoto photoInfo={photos["wouter"]} />
                  </div>
                </div>
              </div>

              <div className="flex flex-wrap -mx-2 ">
                <div className=" w-full  sm:w-1/3 px-2 mb-4 ">
                  <div className="bg-gray-400">
                    <GladiorPhoto photoInfo={photos["michel"]} />
                  </div>
                </div>
                <div className=" w-full  sm:w-1/3 px-2 mb-4 ">
                  <div className="bg-gray-400">
                    <GladiorPhoto photoInfo={photos["dion"]} />
                  </div>
                </div>
                <div className=" w-full  sm:w-1/3 px-2 mb-4 ">
                  <div className="bg-gray-400">
                    <GladiorPhoto photoInfo={photos["carolina"]} />
                  </div>
                </div>
              </div>

              <div className="flex flex-wrap -mx-2 ">
                <div className=" w-full  sm:w-1/3 px-2 mb-4 ">
                  <div className="bg-gray-400">
                    <GladiorPhoto photoInfo={photos["zoeDuFrenne"]} />
                  </div>
                </div>
                <div className=" w-full  sm:w-1/3 px-2 mb-4 ">
                  <div className="bg-gray-400">
                    <GladiorPhoto photoInfo={photos["kieran"]} />
                  </div>
                </div>
                <div className=" w-full  sm:w-1/3 px-2 mb-4 ">
                  <div className="bg-gray-400">
                    <GladiorPhoto photoInfo={photos["michelBeld"]} />
                  </div>
                </div>
              </div>

              <div className="flex flex-wrap -mx-2 flex-row ">
                <div className=" w-full  sm:w-1/3  ">
                  <div className="px-2 mb-3">
                    <GladiorPhoto photoInfo={photos["maarten"]} />
                  </div>
                  <div className="px-2 mb-3">
                    <GladiorPhoto photoInfo={photos["stef-de-ron"]} />
                  </div>
                </div>

                <div className=" w-full  sm:w-2/3 px-2 mb-4 ">
                  <div className="bg-gray-400">
                    <GladiorPhoto photoInfo={photos["team-marketing"]} />
                  </div>
                </div>
              </div>

              <div className="flex flex-wrap -mx-2 ">
                <div className=" w-full  sm:w-1/3 px-2 mb-4 ">
                  <div className="bg-gray-400">
                    <GladiorPhoto photoInfo={photos["thomas"]} />
                  </div>
                </div>
                <div className=" w-full  sm:w-1/3 px-2 mb-4 ">
                  <div className="bg-gray-400">
                    <GladiorPhoto photoInfo={photos["vincent"]} />
                  </div>
                </div>
                <div className=" w-full  sm:w-1/3 px-2 mb-4 ">
                  <div className="bg-gray-400">
                    <GladiorPhoto photoInfo={photos["robin"]} />
                  </div>
                </div>
              </div>

              <div className="flex flex-wrap -mx-2 ">
                <div className=" w-full  sm:w-1/3 px-2 mb-4 ">
                  <div className="bg-gray-400">
                    <GladiorPhoto photoInfo={photos["damian"]} />
                  </div>
                </div>
                <div className=" w-full  sm:w-1/3 px-2 mb-4 ">
                  <div className="bg-gray-400">
                    <GladiorPhoto photoInfo={photos["ellen"]} />
                  </div>
                </div>
                <div className=" w-full  sm:w-1/3 px-2 mb-4 ">
                  <div className="bg-gray-400">
                    <GladiorPhoto photoInfo={photos["karin"]} />
                  </div>
                </div>
              </div>

              <div className="flex flex-wrap -mx-2 ">
                <div className=" w-full  sm:w-2/3 px-2 mb-4 ">
                  <div className="bg-gray-400">
                    <GladiorPhoto photoInfo={photos["team-consultancy"]} />
                  </div>
                </div>
                <div className=" w-full  sm:w-1/3  ">
                  <div className="px-2 mb-3">
                    <GladiorPhoto photoInfo={photos["kai"]} />
                  </div>
                  <div className="px-2 mb-3">
                    <GladiorPhoto photoInfo={photos["tamara"]} />
                  </div>
                </div>
              </div>

              <div className="flex flex-wrap -mx-2 ">
                <div className=" w-full  sm:w-1/3 px-2 mb-4 ">
                  <div className="bg-gray-400">
                    <GladiorPhoto photoInfo={photos["kevin"]} />
                  </div>
                </div>
                <div className=" w-full  sm:w-1/3 px-2 mb-4 ">
                  <div className="bg-gray-400">
                    <GladiorPhoto photoInfo={photos["stefRoelofs"]} />
                  </div>
                </div>
                <div className=" w-full  sm:w-1/3 px-2 mb-4 ">
                  <div className="bg-gray-400">
                    <GladiorPhoto photoInfo={photos["bandi"]} />
                  </div>
                </div>
              </div>

              <div className="flex flex-wrap -mx-2 flex-row ">
                <div className=" w-full  sm:w-1/3  ">
                  <div className="px-2 mb-3">
                    <GladiorPhoto photoInfo={photos["naomi"]} />
                  </div>
                  <div className="px-2 mb-3">
                    <GladiorPhoto photoInfo={photos["marcel"]} />
                  </div>
                </div>

                <div className=" w-full  sm:w-2/3 px-2 mb-4 ">
                  <div className="bg-gray-400">
                    <GladiorPhoto photoInfo={photos["team-sales"]} />
                  </div>
                </div>
              </div>

              <div className="flex flex-wrap -mx-2 ">
                <div className=" w-full  sm:w-1/3 px-2 mb-4 ">
                  <div className="bg-gray-400">
                    <GladiorPhoto photoInfo={photos["rik"]} />
                  </div>
                </div>
                <div className=" w-full  sm:w-1/3 px-2 mb-4 ">
                  <div className="bg-gray-400">
                    <GladiorPhoto photoInfo={photos["zoe"]} />
                  </div>
                </div>
                <div className=" w-full  sm:w-1/3 px-2 mb-4 ">
                  <div className="bg-gray-400">
                    <GladiorPhoto photoInfo={photos["caroline"]} />
                  </div>
                </div>
              </div>
              <div className="flex flex-wrap -mx-2 ">
                <div className=" w-full  sm:w-1/3 px-2 mb-4 ">
                  <div className="bg-gray-400">
                    <GladiorPhoto photoInfo={photos["lianne"]} />
                  </div>
                </div>
                <div className=" w-full  sm:w-1/3 px-2 mb-4 ">
                  <div className="bg-gray-400"></div>
                </div>
                <div className=" w-full  sm:w-1/3 px-2 mb-4 ">
                  <div className="bg-gray-400"></div>
                </div>
              </div>
            </div>
          </div>
        </main>
        <Footer isDark={true} />
      </div>
    </React.Fragment>
  );
}
export default WijZijnGladior;
